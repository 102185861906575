/* eslint-disable react/no-array-index-key */
import { useFormContext, useWatch } from 'react-hook-form';
import { AspectRatio, Box, Center, Flex, Heading } from '@chakra-ui/react';
import Card from 'components/Card';
import { ContainerElement } from 'types/dashboard';
import ContainerPreview from 'pages/LandingPage/components/ContainerPreview';

const LABELS: {
  [key: string]: string;
} = {
  league_table: 'Tabela ligowa',
  teams_list: 'Lista drużyn',
};

function EmptyContainer() {
  return (
    <AspectRatio w="100%" ratio={1}>
      <Flex
        w="100%"
        h="100%"
        bgColor="complementary.whiteGrey"
        justifyContent="center"
        alignItems="center"
        fontWeight={600}
      >
        Tutaj pojawią się kontenery
      </Flex>
    </AspectRatio>
  );
}

type LandingPagePreviewProps = {
  showStats?: boolean;
};

function LandingPagePreview({ showStats = false }: LandingPagePreviewProps) {
  const { control } = useFormContext();
  const containers: ContainerElement[] = useWatch({
    control,
    name: 'containersAttributes',
  });

  return (
    <Card maxW="452px" padding="32px">
      <Heading as="h4" fontSize={16} mb={8} pt={2}>
        PODGLĄD
      </Heading>

      {containers?.length ? (
        containers.map((container, index) => {
          if (container.kind) {
            if (container.active) {
              return (
                <ContainerPreview
                  containerIndex={index}
                  showStats={showStats}
                  key={`container-preview_${index}`}
                />
              );
            }

            if (Object.keys(LABELS).includes(container.kind)) {
              return (
                <Box key={`container-preview_${index}`} py={2}>
                  <Center
                    padding={2}
                    bgColor="complementary.whiteGrey"
                    fontWeight={600}
                  >
                    {LABELS[container.kind]}
                  </Center>
                </Box>
              );
            }

            return null;
          }

          return <EmptyContainer key={`container-preview_${index}`} />;
        })
      ) : (
        <EmptyContainer />
      )}
    </Card>
  );
}

LandingPagePreview.defaultProps = {
  showStats: false,
};

export default LandingPagePreview;

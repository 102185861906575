import * as yup from 'yup';

export const REGULATION_KIND: { [key: string]: string } = {
  general: 'Regulamin ogólny',
  promotion: 'Regulamin promocji',
  declaration: 'Deklaracja zgodności',
  privacy_policy: 'Polityka prywatności',
  loyalty_program: 'Regulamin programu lojalnościowego',
  review_rules: 'Zasady dodawania opinii',
  wear_fair: 'Regulamin Ubraniomatu',
  subscription: 'Regulamin subskrypcji',
  hosting: 'Regulamin korzystania z usług hostingu',
  quiz: 'Regulamin quizu',
  newsletter: 'Regulamin korzyści za zapis do Newslettera',
};

export const BODY_SCHEMA = yup.object({
  title: yup.string().required('Pole jest wymagane'),
  kind: yup.string().required('Pole jest wymagane'),
  attachment1: yup.mixed().nullable(),
  attachment2: yup.mixed().nullable(),
  active: yup.boolean().nullable(),
});

export const DEFAULT_VALUES = {
  title: '',
  kind: '',
  attachment1: null,
  attachment2: null,
  active: false,
};

import { Box, Stack } from '@chakra-ui/react';
import Card from 'components/Card';
import ColorPicker from 'components/Form/Color';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';
import FormSpinner from 'components/FormSpinner';
import { FORM_MAX_WIDTH } from 'utils/constants';
import { TARGET_OPTIONS } from 'pages/SubscriptionRunupScreenElements/Form/constants';

interface FormBodyProps {
  isLoading: boolean;
}

function FormBody({ isLoading }: FormBodyProps) {
  return (
    <Card maxW={FORM_MAX_WIDTH}>
      {isLoading && <FormSpinner />}
      <Box w="100%">
        <Stack spacing={8}>
          <Input name="title" label="Tytuł" placeholder="Wpisz tytuł" />
          <ToggleCheckbox label="Pogrubienie tytułu" name="titleBold" />
          <Input
            name="subtitle"
            label="Podtytuł"
            placeholder="Wpisz podtytuł"
          />
          <ToggleCheckbox label="Pogrubienie podtytułu" name="subtitleBold" />
          <ColorPicker name="textColor" label="Kolor tekstu" />
          <ColorPicker name="backgroundColor" label="Kolor tła" />
          <Select
            name="target"
            label="Rodzaj płatności"
            options={TARGET_OPTIONS}
          />
        </Stack>
      </Box>
    </Card>
  );
}

export default FormBody;

import * as yup from 'yup';

export const BODY_SCHEMA = yup.object({
  title: yup.string().required('Pole jest wymagane'),
  titleBold: yup.boolean(),
  subtitle: yup.string().required('Pole jest wymagane'),
  subtitleBold: yup.boolean(),
  textColor: yup.string().nullable(),
  backgroundColor: yup.string().nullable(),
  target: yup.string().nullable().required('Pole jest wymagane'),
});

export enum TARGET {
  BOTH = 'both',
  CARD = 'card',
  B2B = 'b2b',
}

const TARGET_DICT: {
  [K: string]: string;
} = {
  [TARGET.BOTH]: 'Każdy',
  [TARGET.CARD]: 'Karta płatnicza',
  [TARGET.B2B]: 'Kod',
};

export const TARGET_OPTIONS = Object.values(TARGET).map((type) => ({
  label: TARGET_DICT[type],
  value: type,
}));

export const DEFAULT_VALUES = {
  title: '',
  titleBold: false,
  subtitle: '',
  subtitleBold: false,
  backgroundColor: '#000000',
  textColor: '#FFFFFF',
  target: TARGET.BOTH,
};
